import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

const getModuleColumns = createAsyncThunk(
  "admin/getModuleColumns",
  async (data, { rejectWithValue }) => {
    try {
      const payloadData = {
        tableList: data,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table/onlyFiledList`,
        payloadData,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
export default getModuleColumns;
