import React from "react";

const Loading = ({ width, height, clasName }) => {
  return (
    <>
      <img src={"/loading.svg"} alt="fault" width={width} height={height} />
    </>
  );
};

export default Loading;
