import { PrimeReactProvider } from "primereact/api";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { AudioProvider } from "./AudioProvider";
import Layout from "./commoncomponents/Layout";
import AllUsers from "./pages/AllUsers";
import { AssignedEvent } from "./pages/AssignedEvent/AssignedEvent";
import ManageAssignedEvent from "./pages/AssignedEvent/ManageAssignedEvent";
import AssignedLicense from "./pages/AssignLicense/AssignedLicense";
import AddCategory from "./pages/Category/AddCategory";
import ManageCategory from "./pages/Category/ManageCategory";
import ControlPanel from "./pages/ControlPanel";
import DynamicTab from "./pages/ControlPanel/DynamicTab";
import DetailsPage from "./pages/DetailsPage";
import DeviceStatus from "./pages/DeviceStatus";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ManageModule from "./pages/Module/ManageModule";
import AddProduct from "./pages/node/AddProduct";
import ManageProduct from "./pages/node/ManageProduct";
import AddUser from "./pages/user/AddUser";
import ManageUsers from "./pages/user/ManageUsers";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoutes";

function App() {
  const value = {
    appendTo: "self",
  };
  const location = useLocation();

  useEffect(() => {
    const updateTitle = (pathname) => {
      switch (pathname) {
        case "/home":
          document.title = "Dashboard - EmbeLink";
          break;
        case "/device-status":
          document.title = "Device Status - EmbeLink";
          break;
        case `/device-list`:
          document.title = "Device List - EmbeLink";
          break;
        default:
          document.title = "EmbeLink";
      }
    };

    updateTitle(location.pathname); // Call the updateTitle function whenever the route changes
  }, [location.pathname]);



  return (
    <>
      <PrimeReactProvider value={value}>
        <AudioProvider>
          <Layout>
            <Routes>
              <Route element={<PublicRoute />}>
                <Route path="/" element={<Login />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/home" element={<Home />} />
                <Route path="/addCategry" element={<AddCategory />} />
                <Route path="/addUser" element={<AddUser />} />
                <Route path="/addNode" element={<AddProduct />} />
                <Route path="/manageCategory" element={<ManageCategory />} />
                <Route path="/manageuser" element={<ManageUsers />} />
                <Route path="/manageNode" element={<ManageProduct />} />
                <Route path="/assignedLicense" element={<AssignedLicense />} />
                <Route path="/manageModule" element={<ManageModule />} />
                <Route path="/addAssignEvent" element={<AssignedEvent />} />
                <Route path="/device-status" element={<DeviceStatus />} />
                <Route
                  path="/manageAssignedEvent"
                  element={<ManageAssignedEvent />}
                />
                <Route
                  path="/details/:id/:userId/:currentTab"
                  element={<AllUsers />}
                />
                <Route path="/device-list" element={<DetailsPage />} />
                <Route path="/control-panel" element={<ControlPanel />}>
                  <Route path=":tabName" element={<DynamicTab />}></Route>
                </Route>
              </Route>
            </Routes>
          </Layout>
        </AudioProvider>
      </PrimeReactProvider>
    </>
  );
}

export default App;
