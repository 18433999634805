import { createSlice } from "@reduxjs/toolkit";
import { getCategoriesData } from "../../actions/categories/getCategoriesData";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getCategoriesSlice = createSlice({
  name: "getCategories",
  initialState,
  reducers: {
    // cleargetCategoriessState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoriesData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCategoriesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { cleargetCategoriessState } = getCategoriessSlice.actions;
export default getCategoriesSlice.reducer;
