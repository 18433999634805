export const FormInput = ({
  labelName,
  required,
  name,
  value,
  onChange,
  placeholder,
  error,
  type,
}) => {
  return (
    <div className="form-group">
      <div className="flex">
        <label className="text-[14px]">{labelName}</label>
        {required ? <span className="text-red-700 pl-1">*</span> : null}
      </div>
      <input
        type={type}
        className="form-control text-theme"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <p className="text-red-600 text-[12px]">{error}</p>}
    </div>
  );
};
