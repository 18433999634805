import validator from "validator";

export const loginValidation = (data) => {
  const error = {};

  if (validator.isEmpty(data.email)) {
    error.email = "Please enter email";
  } else if (!validator.isEmail(data.email)) {
    error.email = "Please enter valid email";
  }
  if (validator.isEmpty(data.password)) {
    error.password = "Please enter password";
  }

  return error;
};
