import React, { useEffect } from "react";
import EventsForm from "./EventsForm";
import { useDispatch, useSelector } from "react-redux";
import getEventList from "../../redux/actions/events/getEventList";

export const AssignedEvent = () => {
  const eventsData = useSelector((state) => state.eventsData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventList());
  }, [dispatch]);
  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper p-3">
          <div className="container-fluid p-0">
            <div className="height-100">
              <div className="card caption-top ">
                <div className="card-body rounded">
                  <div className="row p-3">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                      <h5 className="text-xl">Assign Event to Node </h5>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-6 text-end"></div>
                  </div>
                  <EventsForm eventsData={eventsData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
