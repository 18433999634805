import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AllCTPTabs = ({ allCTPData, isLoading }) => {
  const navigate = useNavigate();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { role, id } = JSON.parse(localStorage.getItem("userData")) || {};

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const actionBodyTemplate = (rowData) => {
    if(rowData.tableName.includes("Events" || "events")){
      return (
        <button onClick={() => navigate(`/details/${rowData.id}/${id}/2`)}>
          View <FontAwesomeIcon icon={faEye} />
        </button>
      );
    }
    return (
      <button onClick={() => navigate(`/details/${rowData.id}/${id}/1`)}>
        View <FontAwesomeIcon icon={faEye} />
      </button>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div>
      <DataTable
        value={isLoading ? [] : allCTPData}
        filters={filters}
        removableSort
        tableStyle={{ minWidth: "50rem" }}
        header={header}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        totalRecords={allCTPData?.length}
        emptyMessage={
          isLoading ? (
            <p className="text-center">Loading...</p>
          ) : (
            "No Events Found."
          )
        }
        className="bg-none ct-panel"
        showGridlines
      >
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          body={(_, { rowIndex }) => rowIndex + 1}
          header="Sr. No"
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="node"
          header="Node"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="moduleName"
          header="Module"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="description"
          header="Description"
          sortable
        ></Column>
        {role === 0 && (
          <Column
            className="border-1 text-[14px] p-1 text-center  text-nowrap"
            field="tableName"
            header="Table"
            sortable
          ></Column>
        )}
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="site_name"
          header="Site Name"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="location"
          header="Location"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="EventTriggered"
          header="Event Triggered"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="EventStartedOn"
          header="Event Started On"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center  text-nowrap"
          field="EventEndedOn"
          header="Event Ended On"
          sortable
        ></Column>
        <Column
          className="border-1 py-2 pl-[2rem] text-center  text-nowrap"
          field="status"
          header="Status"
        ></Column>
        <Column
          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center text-nowrap"
          body={actionBodyTemplate}
          header="Action"
        ></Column>
      </DataTable>
    </div>
  );
};

export default AllCTPTabs;
