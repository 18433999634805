import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

export const addProductAction = createAsyncThunk(
  "admin/addProductAction",
  async (addProductData, { rejectWithValue }) => {
    try {
      const {
        name,
        description,
        longitude,
        latitude,
        site_area_location,
        site_name,
        remarks,
      } = addProductData || {};
      const apiData = {
        admin_id: "1",
        name,
        description,
        longitude,
        latitude,
        isActive: true,
        site_area_location,
        site_name,
        remarks,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/node`,
        apiData,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
