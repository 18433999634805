import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAssignedLicenseAction } from "../../redux/actions/license/addAssignedLicenseAction";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import { getUsersAction } from "../../redux/actions/users/getUsersAction";
import { clearaddAssignedLicenseState } from "../../redux/reducers/license/addAssignedLicenseReducer";
import { assignedItemValidation } from "../../validations/assignedItemValidation";

const AddAssignedProduct = ({ setOpenModal, moduleData }) => {
  const [visible, setVisible] = useState(true);
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const productsData = useSelector((state) => state.productsData);
  const addAssignedLicense = useSelector((state) => state.addAssignedLicense);

  useEffect(() => {
    dispatch(getProductAction({ value: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (productsData?.data?.data?.length > 0) {
      setProducts(productsData.data.data);
    }
  }, [productsData]);

  const [error, setError] = useState(null);
  const getUsers = useSelector((state) => state.getUsers);

  useEffect(() => {
    dispatch(getUsersAction({ searchValue: "", signal: null }));
  }, [dispatch]);

  useEffect(() => {
    if (getUsers?.data?.data) {
      setUsers(getUsers?.data?.data);
    }
  }, [getUsers]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCities, setSelectedCities] = useState(null);

  const handleSubmit = () => {
    const updatedData = {
      user: selectedCity?.id ? selectedCity?.id.toString() : "",
      product: selectedCities ? selectedCities : [],
    };
    const errors = assignedItemValidation(updatedData);
    setError(errors);
    if (Object.keys(errors).length === 0) {
      dispatch(addAssignedLicenseAction(updatedData));
    } else {
      setError(errors);
    }
  };

  useEffect(() => {
    if (addAssignedLicense?.data?.status) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: addAssignedLicense?.data?.message,
        life: 3000,
      });
      setTimeout(() => {
        setOpenModal(false);
        dispatch(getUsersAction({ searchValue: "", signal: null }));
      }, 1500);
    } else if (addAssignedLicense?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: addAssignedLicense?.data?.message,
        life: 3000,
      });
    } else if (addAssignedLicense?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: addAssignedLicense.error,
        life: 3000,
      });
    }
    dispatch(clearaddAssignedLicenseState());
  }, [addAssignedLicense, dispatch]);

  return (
    <>
      <div className="card flex justify-content-center">
        <Dialog
          header="Add Assigned Item"
          visible={visible}
          style={{ width: "80vw" }}
          draggable={false}
          onHide={() => {
            setVisible(false);
            setOpenModal(false);
          }}
        >
          <div className="row p-3">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group assigned-item">
                <div className="flex">
                  <label className="text-[14px]">Select User</label>
                  <span className="text-red-700 pl-1">*</span>
                </div>
                <Dropdown
                  filter
                  title={selectedCity?.name}
                  value={selectedCity}
                  onChange={(e) => {
                    setSelectedCity(e.value);
                    setError({ ...error, user: "" });
                  }}
                  options={users}
                  optionLabel="first_name"
                  placeholder="Select a User"
                  className="w-full md:w-14rem h-[3rem] !border border-[#ced4da] hover:border-[#fe4c4c] rounded-none z-100"
                />
                {error?.user && (
                  <p className="text-red-600 text-[12px]">{error?.user}</p>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <div className="flex">
                  <label className="text-[14px]">Select Nodes</label>
                  <span className="text-red-700 pl-1">*</span>
                </div>
                <div className="card flex justify-center">
                  <MultiSelect
                    title={selectedCities?.map((element) => element?.name)}
                    value={selectedCities}
                    filter
                    onChange={(e) => {
                      setSelectedCities(e.value);
                      setError({ ...error, product: "" });
                    }}
                    options={products}
                    optionLabel="name"
                    display="chip"
                    placeholder="Select Nodes"
                    maxSelectedLabels={3}
                    className="w-full md:w-14rem h-[3rem] !border border-[#ced4da] hover:border-[#fe4c4c] rounded-none z-100"
                  />
                  {error?.product && (
                    <p className="text-red-600 text-[12px]">{error?.product}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
              <button
                className="btn btn-light ml-1"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default AddAssignedProduct;
