import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

export const getProductAction = createAsyncThunk(
  "admin/getProductAction",
  async ({ value, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API}/node?searchText=${value ?? ""}`,
        {
          headers: myHeaders(),
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (axios.isCancel(error)) {
        // Request was cancelled, return without setting any error
        return;
      }
      return rejectWithValue(error.message);
    }
  }
);
