import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../redux/actions/forgotPassword/forgotPassword";
import { clearforgotPasswordReducer } from "../redux/reducers/forgotPassword/forgotPasswordReducer";
import { Toast } from "primereact/toast";

const ForgotPasswordModal = ({ setForGotPassModal, forGotPassModal }) => {
  const [emailErr, setEmailErr] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);

  const forgotPasswordResponse = useSelector(
    (state) => state.forgotPasswordReducer
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setEmailValue(value);
    setEmailErr("");
  };

  const handleSentEmail = () => {
    if (validator.isEmpty(emailValue)) {
      setEmailErr("Please enter email");
    } else if (!validator.isEmail(emailValue)) {
      setEmailErr("Please enter valid email");
    } else {
      setIsLoading(true);
      setEmailErr("");
      dispatch(forgotPassword({ email: emailValue }));
    }
  };

  useEffect(() => {
    if (forgotPasswordResponse?.data?.status) {
      setIsLoading(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: forgotPasswordResponse?.data?.message,
        life: 3000,
      });
      dispatch(clearforgotPasswordReducer());
    } else if (forgotPasswordResponse?.data?.status === false) {
      setIsLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: forgotPasswordResponse?.data?.message,
        life: 3000,
      });
      dispatch(clearforgotPasswordReducer());
    } else if (forgotPasswordResponse?.error) {
      setIsLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: forgotPasswordResponse?.error,
        life: 3000,
      });
      dispatch(clearforgotPasswordReducer());
    }
  }, [forgotPasswordResponse]);

  return (
    <>
      <Dialog
        header="Forgot your password?"
        visible={forGotPassModal}
        className="w-[80vw] md:w-[35vw]"
        style={{ borderRadius: "100px", background: "white" }}
        draggable={false}
        onHide={() => {
          setForGotPassModal(false);
        }}
      >
        <div className="p-3">
          <p className="mb-4">We'll email you a link to reset your password.</p>
          <div className="form-group">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              id="email"
              className="form-control mb-0"
              placeholder="Email"
            />
            {emailErr && <p className="text-red-600">{emailErr}</p>}
          </div>
          <button
            id="login"
            className="btn btn-dark rounded-lg mb-2 w-full"
            onClick={handleSentEmail}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </button>
          <button
            id="login"
            className="btn btn-outline-dark rounded-lg w-full"
            onClick={() => setForGotPassModal(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default ForgotPasswordModal;
