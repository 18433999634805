import { createSlice } from "@reduxjs/toolkit";
import { getDataInXslx } from "../../actions/admin/getDataInXslx";
import { getEventsTable } from "../../actions/events/getEventsTable";
import { getEventsTableXslx } from "../../actions/admin/getEventsTableXslx";

const initialState = {
  data: [],
  eventData: [],
  loading: false,
  error: null,
};

const getDataInXslxReducer = createSlice({
  name: "getDataInXslxReducer",
  initialState,
  reducers: {
    cleargetDataInXslxReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataInXslx.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDataInXslx.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDataInXslx.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEventsTableXslx.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventsTableXslx.fulfilled, (state, action) => {
        state.loading = false;
        state.eventData = action.payload;
      })
      .addCase(getEventsTableXslx.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleargetDataInXslxReducer } = getDataInXslxReducer.actions;
export default getDataInXslxReducer.reducer;
