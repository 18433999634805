import validator from "validator";

export const adduserValidation = (data) => {
  const error = {};

  if (validator.isEmpty(data.first_name)) {
    error.first_name = "Please enter first name";
  }
  if (validator.isEmpty(data.last_name)) {
    error.last_name = "Please enter last name";
  }
  if (validator.isEmpty(data.mobile)) {
    error.mobile = "Please enter mobile number";
  }
  if (!validator.isMobilePhone(data.mobile, "en-IN")) {
    error.mobile = "InValid Indian mobile number";
  }

  if (validator.isEmpty(data.gst)) {
    error.gst = "Please enter GST number";
  }

  if (validator.isEmpty(data.address)) {
    error.address = "Please enter address";
  }

  if (validator.isEmpty(data.company)) {
    error.company = "Please enter company name";
  }

  if (validator.isEmpty(data.state)) {
    error.state = "Please enter state name";
  }

  if (validator.isEmpty(data.city)) {
    error.city = "Please enter city name";
  }
  if (validator.isEmpty(data.pin_code)) {
    error.pin_code = "Please enter pincode";
  }
  if (validator.isEmpty(data.lat_long)) {
    error.lat_long = "Please enter location in longitude,latitude format";
  }
  if (validator.isEmpty(data.email)) {
    error.email = "Please enter email";
  } else if (!validator.isEmail(data.email)) {
    error.email = "Please enter valid email";
  }
  if (validator.isEmpty(data.password)) {
    error.password = "Please enter password";
  }
  if (validator.isEmpty(data.confirm_password)) {
    error.confirm_password = "Please enter confirm password";
  } else if (data.password !== data.confirm_password) {
    error.confirm_password = "Passwords do not match";
  }
  if (validator.isEmpty(data.notification_email)) {
    error.notification_email = "Please enter email for notification";
  }
  return error;
};
