import AdminDetailsPage from "./Admin/AdminDetailsPage";
import UserDevice from "./UserDevice";

const DetailsPage = () => {
  const { role } = JSON.parse(localStorage.getItem("userData"));

  return <>{role === 0 ? <AdminDetailsPage /> : <UserDevice />}</>;
};

export default DetailsPage;
