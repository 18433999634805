import { createSlice } from "@reduxjs/toolkit";
import { accessModule } from "../../actions/accessnodes/accessModule";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const accessModuleReducer = createSlice({
  name: "accessModule",
  initialState,
  reducers: {
    clearaccessModuleReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(accessModule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(accessModule.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(accessModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearaccessModuleReducer } = accessModuleReducer.actions;
export default accessModuleReducer.reducer;
