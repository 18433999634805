import * as Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";

// React component to render the chart
const TimeSeriesChart = ({ chartSeries, loading }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (chart) {
      if (loading) {
        chart.showLoading("Loading...");
      } else if (chartSeries?.length === 0) {
        chart.showLoading("No Data Found");
      } else {
        chart.hideLoading();
      }
    }
  }, [chart, loading, chartSeries]);

  let options = {
    chart: {
      type: "line", // or another type that suits your time series data
    },
    title: {
      text: "Time Series Data",
    },
    xAxis: {
      type: "datetime",
    },
    tooltip: {
      formatter: function () {
        const istTimestamp = this.x; // Adjust timestamp to IST
        return `<b>${this.series.name}</b><br/>${Highcharts.dateFormat(
          "%A, %b %e, %Y %H:%M %p",
          istTimestamp
        )}<br/>Value: ${this.y}`;
      },
    },
    plotOptions: {
      line: {
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: chartSeries,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={true}
      constructorType={"chart"}
      callback={(chart) => setChart(chart)}
    />
  );
};

export default TimeSeriesChart;
