import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

const allControlPanelShow = createAsyncThunk(
  "admin/allControlPanelShow",
  async (payload, { rejectWithValue }) => {
    try {
      const { role, id } = JSON.parse(localStorage.getItem("userData"));
      const data = {
        userId: id,
        role: role,
        status: payload.status,
        days: payload.days,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table/all-control-panel-show`,
        data,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

export default allControlPanelShow;
