import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "./service";

export const offlineDeviceName = createAsyncThunk(
  "admin/offlineDeviceName",
  async (_, { rejectWithValue }) => {
    const { role, id } = JSON.parse(localStorage.getItem("userData"));
    const payload = {
      userId: id,
      role: role,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table/node-with-module-details`,
        payload,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
