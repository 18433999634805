import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginAction = createAsyncThunk(
  "admin/loginAction",
  async (loginData, { rejectWithValue }) => {
    try {
      const myHeaders = {
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/auth/login`,
        loginData,
        { headers: myHeaders }
      );
      const {
        access_token,
        mobile,
        gst,
        company,
        email,
        role,
        isActive,
        id,
        first_name,
        assign_product,
      } = response.data || {};
      const now = new Date();
      localStorage.setItem("auth-token", access_token);
      localStorage.setItem("sessionTime", now.getTime());
      const userData = {
        mobile,
        gst,
        company,
        email,
        role,
        isActive,
        name: `${first_name}`,
        admin_id: id,
        assign_product,
        id,
      };
      const userDataString = JSON.stringify(userData);
      localStorage.setItem("userData", userDataString);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
