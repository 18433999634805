// getEventsTableXslx
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

export const getEventsTableXslx = createAsyncThunk(
  "admin/getEventsTableXslx",
  async (filterData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table/eventModuleList`,
        filterData,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
