import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getEventList from "../../redux/actions/events/getEventList";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import EventsForm from "./EventsForm";

const ManageAssignedEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nodesData, setNodeData] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const productsData = useSelector((state) => state.productsData);
  const eventsData = useSelector((state) => state.eventsData);

  useEffect(() => {
    dispatch(getEventList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProductAction({ value: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (productsData?.data?.data) {
      setNodeData(productsData?.data?.data);
    }
  }, [productsData]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleEdit = (rowData, column) => {
    setIsEditModal(true);
    const selectedEventsData = JSON.parse(rowData?.assign_event)?.map((i) => ({
      table_name: i,
      name: i,
    }));
    setEditData(rowData);
    // const newData = findNodeFromId(rowData["assign_product"], products);
  };

  const actionBodyTemplate = (rowData, column) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleEdit(rowData, column)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  const renderCustomColumn = (rowData, column) => {
    const dataLength = JSON.parse(rowData[column.field])?.length || 0;
    let displayData;
    if (dataLength > 3) {
      displayData = (
        <>
          {JSON.parse(rowData[column?.field])?.slice(0, 3)?.join(", ")}
          <span className="ml-1 font-bold">+{dataLength - 3}</span>
        </>
      );
    } else {
      displayData = JSON.parse(rowData[column.field]).join(", ");
    }

    return <span>{displayData}</span>;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid py-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6">
                        <h5 className="text-xl">Assigned Events</h5>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6 text-end mb-1">
                        <button
                          className="btn btn-primary"
                          onClick={() => navigate("/addAssignEvent")}
                        >
                          Add Assigned Event
                        </button>
                      </div>
                    </div>
                    <div className="table-card">
                      <DataTable
                        value={nodesData}
                        filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={[].length}
                        className="bg-none"
                        showGridlines
                      >
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="name"
                          header="Node Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="assign_event"
                          header="Assigned Events"
                          body={renderCustomColumn}
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditModal && (
        <Dialog
          header="Edit Event"
          visible={isEditModal}
          style={{ width: "80vw" }}
          draggable={false}
          onHide={() => {
            setIsEditModal(false);
          }}
        >
          <div className="rounded">
            <EventsForm
              editModal={true}
              editData={editData}
              setIsEditModal={setIsEditModal}
              eventsData={eventsData}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};
export default ManageAssignedEvent;
