import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { addCategoryValidation } from "../../validations/addCategoryValidation";
import { updateCategoryAction } from "../../redux/actions/categories/updateCategoryAction";
import { getCategoriesData } from "../../redux/actions/categories/getCategoriesData";
import { clearaddCategoryState } from "../../redux/reducers/categories/addCategoryReducer";

const EditCategoryModal = ({ setEditModal, EditData }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [data, setData] = useState({
    category: EditData?.name,
    id: EditData?.id,
  });
  const [visible, setVisible] = useState(true);
  const [error, setError] = useState(null);

  const categoryReducer = useSelector((state) => state.addCategory.data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError(null);
  };

  const handleSubmit = () => {
    const errors = addCategoryValidation(data);
    if (Object.keys(errors).length === 0) {
      dispatch(updateCategoryAction(data));
    } else {
      setError(errors);
    }
  };

  useEffect(() => {
    if (categoryReducer?.status === true) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: categoryReducer.message,
        life: 3000,
      });
      setTimeout(() => {
        setVisible(false);
        setEditModal(false);
        dispatch(getCategoriesData());
        dispatch(clearaddCategoryState());
      }, 1000);
    }
  }, [categoryReducer, dispatch]);

  return (
    <>
      <Dialog
        header="Edit Category"
        visible={visible}
        style={{ width: "50vw" }}
        draggable={false}
        onHide={() => {
          setVisible(false);
          setEditModal(false);
        }}
      >
        <div className="card-body rounded">
          <div className="row p-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <div className="flex">
                  <label className="text-[14px]">Add Cateory</label>
                  <span className="text-red-700 pl-1">*</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  name="category"
                  placeholder="Enter Category Name"
                  onChange={handleChange}
                  value={data?.category}
                />
                {error?.category && (
                  <p className="text-red-600 text-[12px]">{error?.category}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
              <button
                className="btn btn-light ml-1"
                onClick={() => {
                  setVisible(false);
                  setEditModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default EditCategoryModal;
