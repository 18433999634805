import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import { getUsersAction } from "../../redux/actions/users/getUsersAction";
import AddAssignedProduct from "./AddAssignedProduct";
import EditAssignItems from "./EditAssignItems";

export const findNodeFromId = (nodeIds, nodeData) => {
  const nodeLabels = [];
  for (let n of nodeIds) {
    const singleNode = nodeData?.filter((item) => item?.id === n);
    nodeLabels.push({ name: singleNode[0]?.name, id: singleNode[0]?.id });
  }
  return nodeLabels;
};

const AssignedLicense = () => {
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const getUsers = useSelector((state) => state.getUsers);
  const productsData = useSelector((state) => state.productsData);

  useEffect(() => {
    const abortSignalController = new AbortController();
    abortControllerRef.current = abortSignalController;

    dispatch(
      getProductAction({ value: "", singal: abortSignalController.signal })
    );

    return () => {
      abortSignalController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (productsData?.data?.data?.length > 0) {
      setProducts(productsData.data.data);
    }
  }, [productsData]);

  useEffect(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    dispatch(
      getUsersAction({ searchValue: "", signal: abortController.signal })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (getUsers?.data?.data?.length > 0) {
      setUsers(getUsers.data.data);
    }
  }, [getUsers]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleEdit = (rowData, column) => {
    setEditModal(true);
    const newData = findNodeFromId(rowData["assign_product"], products);
    setEditData({ ...rowData, assign_product: newData });
  };

  const actionBodyTemplate = (rowData, column) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleEdit(rowData, column)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  const renderCustomColumn = (rowData, column) => {
    const newData = findNodeFromId(rowData[column.field], products);
    const dataLength = newData?.length || 0;
    let displayData;
    if (dataLength > 4) {
      displayData = (
        <>
          {newData
            .slice(0, 4)
            ?.map((item) => item?.name)
            .join(", ")}
          <span className="ml-1 font-bold">+{dataLength - 4}</span>
        </>
      );
    } else {
      displayData = newData?.map((item) => item?.name).join(", ");
    }

    return <span>{displayData}</span>;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid py-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6">
                        <h5 className="text-xl">Assigned Node</h5>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6 text-end mb-1">
                        <button
                          className="btn btn-primary"
                          onClick={() => setOpenModal(true)}
                        >
                          Add Assigned Node
                        </button>
                      </div>
                    </div>
                    <div className="table-card">
                      <DataTable
                        value={users}
                        filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={[].length}
                        className="bg-none"
                        showGridlines
                      >
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="first_name"
                          header="User Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="assign_product"
                          header="Node"
                          body={renderCustomColumn}
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AddAssignedProduct moduleData={products} setOpenModal={setOpenModal} />
      )}
      {editModal && (
        <EditAssignItems
          setEditModal={setEditModal}
          users={users}
          products={products}
          editData={editData}
        />
      )}
    </>
  );
};

export default AssignedLicense;
