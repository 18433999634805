import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { alarmList } from "../../redux/actions/controlPanel/alarmList";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";

const AlarmsTabs = () => {
  const dispatch = useDispatch();
  const moduleData = useSelector((state) => state.alarmListData);
  const [moduleList, setModuleList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const navigate = useNavigate();
  const { role, id } = JSON.parse(localStorage.getItem("userData")) || {};

  useEffect(() => {
    dispatch(alarmList());
  }, [dispatch]);

  useEffect(() => {
    if (moduleData?.data?.length) {
      setModuleList(moduleData?.data);
      setIsLoading(false);
    }
  }, [moduleData]);

  useEffect(() => {
    if (moduleData?.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [moduleData]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  // const switchBodyTemplate = (row) => {
  //   if (row?.status === "Online") {
  //     return <Tag severity="success">Online</Tag>;
  //   } else if (row?.status === "Temporary Offline") {
  //     return <Tag severity="warning">Temporary Offline</Tag>;
  //   } else if (row?.status === "Long Term Offline") {
  //     return <Tag style={{ background: "gray" }}>Long Term Offline</Tag>;
  //   } else {
  //     return <Tag severity="danger">Offline</Tag>;
  //   }
  // };

  const actionBodyTemplate = (rowData) => {
    return (
      <button
        onClick={() =>
          navigate(`/details/${rowData.id}/${id}/2
      `)
        }
      >
        View <FontAwesomeIcon icon={faEye} />
      </button>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <DataTable
        value={isLoading ? [] : moduleList}
        filters={filters}
        removableSort
        tableStyle={{ minWidth: "50rem" }}
        header={header}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        totalRecords={moduleList?.length}
        emptyMessage={
          isLoading ? (
            <p className="text-center">Loading...</p>
          ) : (
            "No Alarms Found."
          )
        }
        className="bg-none"
        showGridlines
      >
        <Column
          className="border-1 text-[14px] p-1 text-center"
          body={(_, { rowIndex }) => rowIndex + 1}
          header="Sr. No"
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="node"
          header="Node"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="moduleName"
          header="Module"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="description"
          header="Description"
          sortable
        ></Column>
        {role === 0 && (
          <Column
            className="border-1 text-[14px] p-1 text-center"
            field="tableName"
            header="Table"
            sortable
          ></Column>
        )}
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="site_name"
          header="Site Name"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="location"
          header="Location"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="EventStartedOn"
          header="Event Started On"
          sortable
        ></Column>
        <Column
          className="border-1 text-[14px] p-1 text-center"
          field="EventEndedOn"
          header="Event Ended On"
          sortable
        ></Column>
        <Column
          className="border-1 py-2 pl-[2rem] text-center"
          // body={switchBodyTemplate}
          field="status"
          header="Status"
        ></Column>
        <Column
          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
          body={actionBodyTemplate}
          header="Action"
        ></Column>
      </DataTable>
    </>
  );
};

export default AlarmsTabs;
