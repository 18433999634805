import { createSlice } from "@reduxjs/toolkit";
import { getUsersWithNode } from "../../actions/admin/getUsersWithNode";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getUsersWithNodeReducer = createSlice({
  name: "getUsersWithNodeReducer",
  initialState,
  reducers: {
    // cleargetUsersState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersWithNode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersWithNode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUsersWithNode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { cleargetUsersState } = getUsersSlice.actions;
export default getUsersWithNodeReducer.reducer;
