import validator from "validator";

export const assignedNodeValidation = (data) => {
  const error = {};
  if (validator.isEmpty(data.product_id)) {
    error.product_id = "Please select node";
  }
  if (data.assign_module.length === 0) {
    error.assign_module = "Please select module";
  }
  // if (data.events.length === 0) {
  //   error.events = "Please select event";
  // }
  return error;
};
