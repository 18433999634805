import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "./service";

export const addAssignedModule = createAsyncThunk(
  "admin/addAssignedModule",
  async (addAssignedLicenseData, { rejectWithValue }) => {
    try {
      const { admin_id } = JSON.parse(localStorage.getItem("userData"));
      const data = {
        product_id: addAssignedLicenseData?.product_id,
        assign_module: addAssignedLicenseData?.assign_module,
        admin_id: admin_id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table`,
        data,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
