import { createSlice } from "@reduxjs/toolkit";
import getEventList from "../../actions/events/getEventList";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getEventListSlice = createSlice({
  name: "getEventList",
  initialState,
  reducers: {
    // cleargetEventListsState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getEventList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { cleargetEventListsState } = getEventListsSlice.actions;
export default getEventListSlice.reducer;
