import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

export const addAssignedEvent = createAsyncThunk(
  "admin/addAssignedEvent",
  async (addAssignedEventData, { rejectWithValue }) => {
    try {
      const { admin_id } = JSON.parse(localStorage.getItem("userData"));
      const data = {
        admin_id,
        product_id: addAssignedEventData?.product_id,
        assign_event: addAssignedEventData?.events,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table/assignEventInNode`,
        data,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
