import { createSlice } from "@reduxjs/toolkit";
import { updateUserAction } from "../actions/users/updateUserAction";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const updateUserSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    clearUpdateUserState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUpdateUserState } = updateUserSlice.actions;
export default updateUserSlice.reducer;
