import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChartData } from "../redux/actions/admin/getChartData";
import { Toast } from "primereact/toast";
import LiveChartTab from "../pages/Admin/LiveChartTab";
import RealTimeData from "../pages/Admin/RealTimeData";
import { getDataByDate } from "../redux/actions/admin/getDataByDate";
import { cleargetDataByDateReducer } from "../redux/reducers/admin/getDataByDateReducer";
import { getEventsTable } from "../redux/actions/events/getEventsTable";
import { rearrangeArray } from "../utils/converData";

const SubTab = ({ tabList, activeParentTab }) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [activeTab, setActiveTab] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [clearDateFilter, setClearDateFilter] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const [tabularData, setTabularData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const tableData = useSelector((state) => state.getDataByDateReducer);
  const singleEventData = useSelector((state) => state.getEventsTable);
  useEffect(() => {
    if (!!tabList.length) {
      setActiveTab(rearrangeArray(tabList)?.[0]?.table_name);
    }
  }, [tabList]);

  useEffect(() => {
    handleTabClick(rearrangeArray(tabList)?.[0]?.table_name);
  }, []);

  const handleTabClick = (tabIndex) => {
    let previousDate = new Date();
    previousDate.setDate(previousDate.getDate() - 30);
    dispatch(cleargetDataByDateReducer());
    setCurrentPage(0);
    setClearDateFilter(true);
    setActiveTab(tabIndex);
    if (activeParentTab === 1) {
      dispatch(getChartData(tabIndex));
      return;
    } else if (activeParentTab === 2) {
      const existingRealFilters =
        localStorage.getItem(`dateFilter_${activeParentTab}_${tabIndex}`) ||
        localStorage.getItem(`realTimeFilter_${activeParentTab}_${tabIndex}`);
      if (!existingRealFilters) {
        const dateFilters = [previousDate, new Date(), "30"];
        localStorage.setItem(
          `dateFilter_${activeParentTab}_${tabIndex}`,
          dateFilters
        );
      }
      const sDate = !existingRealFilters
        ? previousDate
        : existingRealFilters?.split(",")?.[0];
      const eDate = !existingRealFilters
        ? new Date()
        : existingRealFilters?.split(",")?.[1];
      setStartDate(new Date(sDate));
      setEndDate(new Date(eDate));
      const data = {
        startDate: sDate,
        endDate: eDate,
        page: 0,
        searchKey: "",
        actionType: "",
        table: tabIndex,
      };

      dispatch(getEventsTable(data));
      return;
    } else if (activeParentTab === 4) {
      const existingRealFilters = localStorage.getItem(
        `dateFilter_${activeParentTab}_${tabIndex}`
      );
      if (!existingRealFilters) {
        const dateFilters = [previousDate, new Date(), "0"];
        localStorage.setItem(
          `dateFilter_${activeParentTab}_${tabIndex}`,
          dateFilters
        );
      }
      const sDate = !existingRealFilters
        ? previousDate
        : existingRealFilters?.split(",")?.[0];
      const eDate = !existingRealFilters
        ? new Date()
        : existingRealFilters?.split(",")?.[1];

      const data = {
        startDate: sDate,
        endDate: eDate,
        page: 0,
        table: tabIndex,
      };
      dispatch(getDataByDate(data));
    } else {
      const existingRealFilters =
        localStorage.getItem(`dateFilter_${activeParentTab}_${tabIndex}`) ||
        localStorage.getItem(`realTimeFilter_${activeParentTab}_${tabIndex}`);
      if (!existingRealFilters) {
        const dateFilters = [previousDate, new Date(), "1hr"];
        localStorage.setItem(
          `dateFilter_${activeParentTab}_${tabIndex}`,
          dateFilters
        );
      }
      const sDate = !existingRealFilters
        ? previousDate
        : existingRealFilters?.split(",")?.[0];
      const eDate = !existingRealFilters
        ? new Date()
        : existingRealFilters?.split(",")?.[1];
      setStartDate(new Date(sDate));
      setEndDate(new Date(eDate));
      const data = {
        startDate: sDate,
        endDate: eDate,
        page: 0,
        table: tabIndex,
      };
      dispatch(getDataByDate(data));
    }
  };

  useEffect(() => {
    if (tableData) {
      setTabularData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (singleEventData) {
      setEventData(singleEventData);
    }
  }, [singleEventData]);

  useEffect(() => {
    if (!resetFilter) {
      if (activeParentTab === 3) {
        const existingRealFilters = localStorage.getItem(
          `dateFilter_${activeParentTab}_${activeTab}`
        );
        const interval = setInterval(() => {
          const endDate = "";
          const startDate = "";
          const data = {
            startDate: !existingRealFilters
              ? startDate
              : existingRealFilters?.split(",")?.[0],
            endDate: !existingRealFilters
              ? endDate
              : existingRealFilters?.split(",")?.[1],
            page: currentPage,
            table: activeTab,
          };
          dispatch(getDataByDate(data));
        }, 10000);

        return () => clearInterval(interval);
      } else if (activeParentTab === 2) {
        const existingRealFilters = localStorage.getItem(
          `dateFilter_${activeParentTab}_${activeTab}`
        );
        let previousDate = new Date();
        previousDate.setDate(previousDate.getDate() - 30);
        const interval = setInterval(() => {
          const endDate = new Date();
          const startDate = previousDate;
          const data = {
            startDate: !existingRealFilters
              ? startDate
              : existingRealFilters?.split(",")?.[0],
            endDate: !existingRealFilters
              ? endDate
              : existingRealFilters?.split(",")?.[1],
            page: currentPage,
            searchKey: "",
            actionType: "",
            table: activeTab,
          };

          dispatch(getEventsTable(data));
        }, 10000);

        return () => clearInterval(interval);
      }
    }
  }, [dispatch, activeTab, currentPage, activeParentTab, resetFilter]);

  return (
    <div className="mt-2">
      {activeParentTab === 3 && (
        <ul className="nav nav-tabs" role="tablist">
          {rearrangeArray(tabList)?.map((item, idx) => (
            <li
              className="nav-item cursor-pointer"
              key={`${idx}_${item?.table_name}_#`}
            >
              <p
                className={`nav-link ${
                  activeTab === item?.table_name ? "active" : ""
                }`}
                onClick={() => handleTabClick(item?.table_name)}
                data-toggle="tab"
              >
                {activeParentTab === 3 ? item?.name : item?.table_name}
              </p>
            </li>
          ))}
        </ul>
      )}
      <div>
        {/* {activeParentTab === 1 && <LiveChartTab tabList={tabList} />} */}
        {(activeParentTab === 3 || activeParentTab === 2) && (
          <RealTimeData
            filterData={activeParentTab === 3 ? tabularData : eventData}
            activeParentTab={activeParentTab}
            tabIndex={activeTab}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            clearDateFilter={clearDateFilter}
            setClearDateFilter={setClearDateFilter}
            setResetFilter={setResetFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )}
      </div>
      <Toast ref={toast} className="text-sm" />
    </div>
  );
};

export default SubTab;
