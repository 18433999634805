import { createSlice } from "@reduxjs/toolkit";
import { onlineOfflineCount } from "../../actions/alarmTripCount/onlineOfflineCount";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const onlineOfflineCountReducer = createSlice({
  name: "onlineOfflineCountReducer",
  initialState,
  reducers: {
    clearonlineOfflineCountReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(onlineOfflineCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(onlineOfflineCount.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(onlineOfflineCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearonlineOfflineCountReducer } =
  onlineOfflineCountReducer.actions;
export default onlineOfflineCountReducer.reducer;
