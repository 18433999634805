import { createSlice } from "@reduxjs/toolkit";
import { getModuleWiseChartData } from "../../actions/admin/getModuleWiseChartData";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getTimeSeriesDataReducer = createSlice({
  name: "accessModule",
  initialState,
  reducers: {
    clearTimeSeriesDataState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModuleWiseChartData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getModuleWiseChartData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getModuleWiseChartData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearTimeSeriesDataState } = getTimeSeriesDataReducer.actions;
export default getTimeSeriesDataReducer.reducer;
