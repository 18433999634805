import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "./service";

export const addUserAction = createAsyncThunk(
  "admin/addUserAction",
  async (addUserData, { rejectWithValue }) => {
    try {
      const {
        first_name,
        last_name,
        mobile,
        gst,
        address,
        state,
        city,
        pin_code,
        company,
        email,
        password,
        lat_long,
        notification_email,
      } = addUserData;
      const apiData = {
        first_name,
        last_name,
        mobile,
        gst,
        address,
        state,
        city,
        pin_code,
        company,
        email,
        password,
        lat_long,
        notification_email: notification_email,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/users`,
        apiData,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
