import { createSlice } from "@reduxjs/toolkit";
import { getChartData } from "../../actions/admin/getChartData";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getChartDataReducer = createSlice({
  name: "getChartDataReducer",
  initialState,
  reducers: {
    cleargetChartDataReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChartData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getChartData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getChartData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleargetChartDataReducer } = getChartDataReducer.actions;
export default getChartDataReducer.reducer;
