import { createSlice } from "@reduxjs/toolkit";
import allControlPanelShow from "../../actions/controlPanel/allControlPanelShow";
import { converDate } from "../../../utils/converData";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const allControlPanelReducer = createSlice({
  name: "allControlPanelShow",
  initialState,
  reducers: {
    clearAllControlPanelShow: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(allControlPanelShow.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(allControlPanelShow.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.data = action.payload;
        } else {
          state.data = [];
        }
      })
      .addCase(allControlPanelShow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearAllControlPanelShow } = allControlPanelReducer.actions;
export default allControlPanelReducer.reducer;
