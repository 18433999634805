import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import React, { useEffect, useState } from "react";

const PieChart = ({ data, title, isLoading }) => {
  const [chart, setChart] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (chart) {
      if (isLoading) {
        chart.showLoading("Loading data...");
      } else {
        chart.hideLoading();
        setChartData(data);
      }
    }
  }, [chart, isLoading, data]);

  let options = {
    chart: {
      type: "pie", // or another type that suits your time series data
      animation: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      type: "datetime",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Count",
        colorByPoint: true,
        data: chartData,
        showLegend: true,
        // dataLabels: {
        //   enabled: false,
        // },
        dataLabels: [
          {
            enabled: false,
            format: "{point.y}",
            style: {
              fontSize: "1 rem",
              textOutline: "none",
              opacity: 0.7,
            },
          },
          {
            enabled: true,
            distance: -35,
            // format: "{point.percentage:.1f}%",
            format: "{point.y}",
            style: {
              fontSize: "0.5 rem",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "y",
              value: 0,
            },
          },
        ],
        animation: false,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={true}
      constructorType={"chart"}
      callback={(chart) => setChart(chart)}
    />
  );
};

export default PieChart;
